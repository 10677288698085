.Work-Job {
	color: pink;
	font: 900;
}
.Work-Title * {
	font-family: 43px;
	/* color: rgb(171, 104, 156); */
}

.Work-Job {
	color: rgb(0, 230, 0);
	font-size: 1.2em;
	font-weight: 600;
	margin-bottom: .5em;
}

.Work-Date {
	color: rgb(0, 230, 0);
	font-size: 1em;
	font-style: italic;
	margin-top: .5em;
}
.Work-Position {
	color: rgb(255, 86, 227);
	font-size: 1em;
	font-weight: 600;
}
.Work-Detail li {
	color: rgb(0, 230, 0);
	font-size: 1em;
	line-height: 1.5em;
}
@media only screen and (max-width: 830px) {
	.Work-Detail li {
		margin-left: -1.5em;
	}
}
