.marker {
	color: white;
	margin-left: -1.8rem;
	display: inline-block;
	font-size: medium;
}
.old-command {
	margin-left: .1rem;
	font-size: medium;
}
.TermData span {
	position: relative;
	top: 0;
	display: inline-block;
}
