.AboutMe-Intro p {
	color: rgb(0, 230, 0);
}

.AboutMe-Intro ul li {
	color: rgb(0, 230, 0);
	line-height: 1.5em;
}

@media only screen and (max-width: 830px) {
	.AboutMe-Intro ul li {
		margin-left: -1.5em;
	}
}
